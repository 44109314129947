import api from "../services/api";
import { notification } from "antd";
import messages from "../helpers/messages";

/**
 * Action types
 */
export const Types = {
  LOADING: "spreadsheetProduct/LOADING",
  UPLOADS: "spreadsheetProduct/UPLOADS",
  TOTAL_PRODUCTS: "spreadsheetProduct/TOTAL_PRODUCTS"
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  loading: false,
  uploads: [],
  totals: {
    totalProductsAdded: null,
    totalProductsHasExists: null,
    totalProductsNotAdded: null
  }
};

/**
 * Action creators
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.LOADING:
      return { ...state, loading: action.payload };
    case Types.UPLOADS:
      return { ...state, uploads: action.payload };
    case Types.TOTAL_PRODUCTS:
      return {
        ...state,
        totals: {
          productsAdded: action.totalProductsAdded,
          productsHasExists: action.totalProductsHasExists,
          productsNotAdded: action.totalProductsNotAdded
        }
      };
    default:
      return state;
  }
};

/**
 * Creators
 */
export const Creators = {
  setLoading: loading => ({
    type: Types.LOADING,
    payload: loading
  }),
  // Set Upload File in Reducer
  setUploads: uploads => ({
    type: Types.UPLOADS,
    payload: uploads
  }),
  // Add
  add: setSubmitting => (dispatch, getState) => {
    dispatch([Creators.setLoading(true), setSubmitting(true)]);

    let formData = new FormData();
    const { uploads } = getState().SpreadsheetProductReducer;

    if (uploads.length > 0) {
      uploads.map(file => {
        formData.append("files", file.originFileObj);
      });

      api
        .post("/spreadsheetsProducts", formData)
        .then(response => {
          const {
            totalProductsAdded,
            totalProductsHasExists,
            totalProductsNotAdded
          } = response.data;

          notification["success"]({
            message: "Importar Planilha de Produtos",
            description: messages.SUCCESS
          });

          dispatch([
            {
              type: Types.TOTAL_PRODUCTS,
              totalProductsAdded,
              totalProductsHasExists,
              totalProductsNotAdded
            },
            Creators.setUploads([]),
            Creators.setLoading(false),
            setSubmitting(false)
          ]);
        })
        .catch(error => {
          const { message } = error;
          if (message) {
            notification["error"]({
              message: "Importar Planilha de Produtos",
              description: message
            });
          }

          dispatch([Creators.setLoading(false), setSubmitting(false)]);
        });
    } else {
      dispatch([Creators.setLoading(false), setSubmitting(false)]);

      notification["warning"]({
        message: "Importar Planilha de Produtos",
        description: "Você deve enviar pelo menos uma planilha"
      });
    }
  }
};
