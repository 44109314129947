import React, { Component } from "react";
import { Skeleton } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as DashboardActions } from "../../ducks/dashboard";

import Header from "../../components/theme/header";
import Content from "../../components/theme/content";

import Box from "../../components/theme/box";
import { Row, Column, Panel } from "../../components/bootstrap";

import Grid from "./grid";

class Dashboard extends Component {
  componentDidMount() {
    this.props.dashboardActions.get();
  }

  render() {
    const {
      loading,
      totalClients,
      totalProducts,
      totalProductsNotFound,
      totalsPerClient
    } = this.props.dashboardReducer;
    return (
      <>
        <Header title="Dashboard" />
        <Content>
          <Panel title="Resumo" color="primary">
            {loading ? (
              <Skeleton active />
            ) : (
              <>
                <Row>
                  <Box
                    col="4"
                    title="Geral"
                    description="Clientes"
                    amount={totalClients}
                    amountDescription="Total"
                  />
                  <Box
                    col="4"
                    title="Geral"
                    description="Produtos"
                    amount={totalProducts}
                    amountDescription="Total"
                  />
                  <Box
                    col="4"
                    title="Geral"
                    description="Produtos não Encontrados"
                    amount={totalProductsNotFound}
                    amountDescription="Total"
                  />
                </Row>
                <Row>
                  <Column col="12">
                    <Grid
                      title="Clientes"
                      dataSource={totalsPerClient}
                      loading={loading}
                    />
                  </Column>
                </Row>
              </>
            )}
          </Panel>
        </Content>
      </>
    );
  }
}

const mapStateToProps = state => ({
  dashboardReducer: state.DashboardReducer
});

const mapDispatchToProps = dispatch => ({
  dashboardActions: bindActionCreators(DashboardActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
