import React, { Component } from "react";
import { withFormik } from "formik";
import { Button } from "antd";
import { Link, withRouter } from "react-router-dom";
import * as yup from "yup";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as ProductActions } from "../../ducks/product";
import ImagesTable from "./components/table";

import {
  InputText,
  InputNumber,
  TextAreaDescription,
} from "../../components/form";
import { Panel, Row, Column, Form, Br } from "../../components/bootstrap";
import messages from "../../helpers/messages";

class InnerForm extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    //verifica se existe id
    if (this.props.match.params.id) {
      this.props.productActions.get(
        this.props.match.params.id,
        this.props.setValues
      );
      this.props.productActions.getImages(this.props.match.params.id);
    }
  }

  handleChange(name, value) {
    this.props.setFieldValue(name, value);
  }

  render() {
    const { values, errors, isSubmitting, handleSubmit, handleChange } =
      this.props;
    const { images } = this.props.productReducer;
    return (
      <Form handleSubmit={handleSubmit}>
        <Panel color="primary" title="Produtos">
          <Row>
            {/* ean */}
            <InputText
              name="ean"
              handleChange={handleChange}
              col={6}
              required
              error={errors.ean}
              value={values.ean}
              label="EAN"
            />
            {/* originalCode */}
            <InputText
              name="originalCode"
              handleChange={handleChange}
              col={6}
              error={errors.originalCode}
              value={values.originalCode}
              label="Código Original"
            />
          </Row>
          <Row>
            {/* name */}
            <InputText
              name="name"
              handleChange={handleChange}
              col={12}
              required
              error={errors.name}
              value={values.name}
              label="Nome"
            />
          </Row>
          <Row>
            {/* description */}
            <TextAreaDescription
              name="description"
              handleChange={handleChange}
              col={12}
              error={errors.description}
              value={values.description}
              label="Descrição"
              rows="5"
            />
          </Row>
          <Row>
            {/* image */}
            <InputText
              name="image"
              handleChange={handleChange}
              col={12}
              error={errors.image}
              value={values.image}
              label="Imagem URL"
            />
          </Row>
          <Panel color="default" title="Demais">
            <Row>
              {/* brand */}
              <InputText
                name="brand"
                handleChange={handleChange}
                col={3}
                error={errors.brand}
                value={values.brand}
                label="Marca"
              />
              {/* manufacturer */}
              <InputText
                name="manufacturer"
                handleChange={handleChange}
                col={3}
                error={errors.manufacturer}
                value={values.manufacturer}
                label="Fabricante"
              />
              {/* price */}
              <InputNumber
                name="price"
                handleChange={(value) => this.handleChange("price", value)}
                col={3}
                error={errors.price}
                value={values.price}
                label="Preço"
              />
              {/* url */}
              <InputText
                name="url"
                handleChange={handleChange}
                col={3}
                error={errors.url}
                value={values.url}
                label="Url"
              />
            </Row>
          </Panel>
          <Panel color="default" title="Dimensões">
            <Row>
              {/* length */}
              <InputNumber
                name="length"
                handleChange={(value) => this.handleChange("length", value)}
                col={3}
                error={errors.length}
                value={values.length}
                label="Comprimento"
              />
              {/* width */}
              <InputNumber
                name="width"
                handleChange={(value) => this.handleChange("width", value)}
                col={2}
                error={errors.width}
                value={values.width}
                label="Largura"
              />
              {/* height */}
              <InputNumber
                name="height"
                handleChange={(value) => this.handleChange("height", value)}
                col={2}
                error={errors.height}
                value={values.height}
                label="Altura"
              />
              {/* weight */}
              <InputNumber
                name="weight"
                handleChange={(value) => this.handleChange("weight", value)}
                col={3}
                error={errors.weight}
                value={values.weight}
                label="Peso (Kg)"
              />
            </Row>
          </Panel>
        </Panel>
        <Row>
          <Column col="12">
            <Button
              htmlType="submit"
              className="pull-right"
              type="primary"
              loading={isSubmitting}
            >
              Salvar
            </Button>
            <p className="pull-right" style={{ marginRight: 8, paddingTop: 8 }}>
              <Link to="/product">Cancelar</Link>
            </p>
          </Column>
        </Row>
        {!!images && images.length > 0 && (
          <Panel color="primary" title="Imagens" noPadding>
            <div className="ant-table-wrapper">
              <div className="ant-spin-nested-loading">
                <div className="ant-spin-container">
                  <div className="ant-table ant-table-small ant-table-scroll-position-left">
                    <div className="ant-table-content">
                      <div className="ant-table-body">
                        {/* Lista de imagens */}
                        <ImagesTable images={images} productId={this.props.match.params.id} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Panel>
        )}
        <Br />
      </Form>
    );
  }
}

const ProductForm = withFormik({
  validateOnChange: false,
  mapPropsToValues: ({
    ean,
    originalCode,
    name,
    description,
    image,
    manufacturer,
    price,
    width,
    height,
    depth,
    weight,
    url,
  }) => ({
    ean: ean || "",
    originalCode: originalCode || "",
    name: name || "",
    description: description || "",
    image: image || "",
    manufacturer: manufacturer || "",
    price: price || "",
    width: width || "",
    height: height || "",
    depth: depth || "",
    weight: weight || "",
    url: url || "",
  }),
  validationSchema: yup.object().shape({
    ean: yup.string().required(messages.REQUIRED),
    name: yup.string().required(messages.REQUIRED),
    // url: yup
    //   .string()
    //   .url(messages.URL)
    //   .required(messages.REQUIRED)
    //   .typeError(messages.INVALID_URL),
    // image: yup
    //   .string()
    //   .url(messages.URL)
    //   .required(messages.REQUIRED)
    //   .typeError(messages.INVALID_NULL),
    // weight: yup
    //   .number()
    //   .required(messages.REQUIRED)
    //   .typeError(messages.INVALID_NUMBER)
  }),
  handleSubmit(values, { props, resetForm, setErrors, setSubmitting }) {
    if (!props.match.params.id) {
      //props.productActions.add(values, resetForm, setErrors, setSubmitting);
    } else {
      props.productActions.update(
        { id: props.match.params.id, ...values },
        setErrors,
        setSubmitting
      );
    }
  },
})(InnerForm);

const mapStateToProps = (state) => ({
  productReducer: state.ProductReducer,
  productNotFoundReducer: state.ProductNotFoundReducer,
});

const mapDispatchToProps = (dispatch) => ({
  productActions: bindActionCreators(ProductActions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductForm)
);
