import React, { Component } from "react";
import { withFormik } from "formik";
import { Button } from "antd";
import { Link, withRouter } from "react-router-dom";
import * as Yup from "yup";

//components
import { InputText } from "../../components/form";
import { Panel, Row, Column, Form, Br } from "../../components/bootstrap";
import Messages from "../../helpers/messages";

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as ClientActions } from "../../ducks/client";

//form
class InnerForm extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    //verifica se existe id
    if (this.props.match.params.id) {
      this.props.clientActions.get(
        this.props.match.params.id,
        this.props.setValues
      );
    }
  }

  handleChange(name, value) {
    this.props.setFieldValue(name, value);
  }

  render() {
    const {
      values,
      errors,
      isSubmitting,
      handleSubmit,
      handleChange
    } = this.props;
    return (
      <Form handleSubmit={handleSubmit}>
        <Panel color="primary" title="Clientes">
          <Row>
            {/* name */}
            <InputText
              name="name"
              handleChange={handleChange}
              col={12}
              required
              error={errors.name}
              value={values.name}
              label="Nome"
            />
          </Row>
        </Panel>
        <Row>
          <Column col="12">
            <Button
              htmlType="submit"
              className="pull-right"
              type="primary"
              loading={isSubmitting}
            >
              Salvar
            </Button>
            <p className="pull-right" style={{ marginRight: 8, paddingTop: 8 }}>
              <Link to="/client">Cancelar</Link>
            </p>
          </Column>
        </Row>
        <Br />
      </Form>
    );
  }
}

const FormClient = withFormik({
  validateOnChange: false,
  mapPropsToValues: ({ name }) => ({
    name: name || ""
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required(Messages.REQUIRED)
  }),
  handleSubmit(values, { props, resetForm, setErrors, setSubmitting }) {
    if (!props.match.params.id) {
      props.clientActions.add(values, resetForm, setErrors, setSubmitting);
    } else {
      props.clientActions.update(
        { id: props.match.params.id, ...values },
        setErrors,
        setSubmitting
      );
    }
  }
})(InnerForm);

const mapStateToProps = state => ({
  clientReducer: state.ClientReducer
});

const mapDispatchToProps = dispatch => ({
  clientActions: bindActionCreators(ClientActions, dispatch)
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FormClient)
);
