import React, { Component } from "react";
import $ from "jquery";

import Progress from "./progress";
import Navigation from "./navigation";
import TopHeader from "./topHeader";
import Footer from "./footer";
import { correctHeight, detectBody } from "./helpers/helpers";
import { Router } from "react-router-dom";
import history from "../../helpers/history";

// CSS and JS
import "./assets/dependencies";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as LoginActions } from "../../ducks/login";

// Routes
import Routes from "../../routes";

class Main extends Component {
  componentDidMount() {
    $(window).bind("load resize", function() {
      correctHeight();
      detectBody();
    });

    //Verifica se o cliente está logado no reload da página.
    this.props.loginActions.setLogged(null);
  }

  render() {
    if (this.props.loginReducer.logged) {
      return (
        <div id="wrapper">
          <Router history={history}>
            <div>
              <Progress />
              <Navigation />
              <div id="page-wrapper" className="gray-bg">
                <TopHeader />
                <Routes />
                <Footer />
              </div>
            </div>
          </Router>
        </div>
      );
    } else {
      return (
        <div className="bg-login">
          <Router history={history}>
            <Routes />
          </Router>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  loginReducer: state.LoginReducer
});

const mapDispatchToProps = dispatch => ({
  loginActions: bindActionCreators(LoginActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Main);
