import { combineReducers } from "redux";

import LoginReducer from "./login";
import DashboardReducer from "./dashboard";
import SpreadsheetClientReducer from "./spreadsheetClient";
import SpreadsheetProductReducer from "./spreadsheetProduct";
import ProductReducer from "./product";
import ProductNotFoundReducer from "./productNotFound";
import ProductInconsistencyReducer from "./productInconsistency";
import ProductErrorReducer from "./productError";
import ClientReducer from "./client";
import UserReducer from "./user";

import ConfigurationReducer from "./configuration";

export default combineReducers({
  LoginReducer,
  DashboardReducer,
  SpreadsheetClientReducer,
  SpreadsheetProductReducer,
  ProductReducer,
  ProductNotFoundReducer,
  ProductInconsistencyReducer,
  ProductErrorReducer,
  ClientReducer,
  UserReducer,
  ConfigurationReducer
});
