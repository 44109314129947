import React, { Component } from "react";
import { connect } from "react-redux";

import Form from "./form";
import { Row, Column, Center, H3, Div } from "../../components/bootstrap";

export class Login extends Component {
  render() {
    return (
      <Row>
        <Column col="4" />
        <Column col="4">
          <div className="animated fadeInDown" style={{ marginTop: 100 }}>
            <Row>
              <Column col="12">
                <div
                  className="ibox-content"
                  style={{ border: "1px solid #eee" }}
                >
                  <center>
                    <img src="" />
                  </center>
                  <h3 className="font-bold">
                    <Center>Seja bem-vindo!</Center>
                  </h3>
                  <Form />
                  <p className="m-t">
                    <small>Forsh Commerce {new Date().getFullYear()}.</small>
                  </p>
                </div>
              </Column>
              <Column col="3" />
            </Row>
          </div>
        </Column>
        <Column col="4" />
      </Row>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
