import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

//login
import Login from "../pages/login";

//dashboard
import Dashboard from "../pages/dashboard";

//spreadsheet
import SpreadsheetClient from "../pages/spreadsheet/client";
import SpreadsheetProduct from "../pages/spreadsheet/product";

//products
import Product from "../pages/product";
import ProductEdit from "../pages/product/edit";
import ProductNotFound from "../pages/product/notFound";
import ProductInconsistency from "../pages/product/inconsistency";
import ProductError from "../pages/product/error";

//clients
import Client from "../pages/client";
import ClientEdit from "../pages/client/edit";

//users
import User from "../pages/user";
import UserEdit from "../pages/user/edit";

const PrivateRouter = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !!localStorage.getItem("@forshProduct:token") ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            exact: true,
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

const Routes = () => (
  <Switch>
    {/* Login */}
    <Route exact path="/login" component={Login} />
    {/* Dashboard */}
    <PrivateRouter exact path="/" component={Dashboard} />
    <PrivateRouter exact path="/dashboard" component={Dashboard} />
    {/* Product */}
    <PrivateRouter exact path="/product" component={Product} />
    <PrivateRouter path="/product/edit/:id" component={ProductEdit} />
    <PrivateRouter path="/product/notfound" component={ProductNotFound} />
    <PrivateRouter
      path="/product/inconsistency"
      component={ProductInconsistency}
    />
    <PrivateRouter path="/product/error" component={ProductError} />
    {/* Spreadsheet */}
    <PrivateRouter
      exact
      path="/spreadsheet/client"
      component={SpreadsheetClient}
    />
    <PrivateRouter
      exact
      path="/spreadsheet/product"
      component={SpreadsheetProduct}
    />
    {/* Client */}
    <PrivateRouter exact path="/client" component={Client} />
    <PrivateRouter exact path="/client/add" component={ClientEdit} />
    <PrivateRouter exact path="/client/edit/:id" component={ClientEdit} />
    {/* User */}
    <PrivateRouter exact path="/user" component={User} />
    <PrivateRouter path="/user/add" component={UserEdit} />
    <PrivateRouter path="/user/edit/:id" component={UserEdit} />
    {/* <Route path="*" exact component={My404Component} /> */}
  </Switch>
);

export default Routes;
