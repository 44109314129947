import React, { Component } from "react";
import { Table, Popover } from "antd";
import { Panel } from "../../components/bootstrap";

class DashboardGrid extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: "Cliente",
        dataIndex: "name",
        render: text => <strong>{text}</strong>
      },
      {
        title: "Produtos Importados",
        dataIndex: "importedTotal"
      },
      {
        title: "Produtos Encontrados",
        dataIndex: "foundTotal"
      },
      {
        title: "Produtos Não Encontrados",
        dataIndex: "notFoundTotal"
      },
      {
        title: "Produtos Completos",
        dataIndex: "completedTotal",
        render: text => <strong>{text}</strong>
      },
      {
        title: "Produtos Encontrados (Incosistentes)",
        dataIndex: "inconsistencyTotal",
        render: (text, record) => (
          <>
            <Popover
              content={this.content(
                record.inconsistencyTotalImage,
                record.inconsistencyTotalWeight
              )}
              title="Inconsistências"
            >
              <a href="#">{text}</a>
            </Popover>
          </>
        )
      }
    ];
  }

  content = (productsFoundNoImage, productsFoundNoWeight) => (
    <div>
      <p>
        Sem Imagem: <strong>{productsFoundNoImage}</strong>
      </p>
      <p>
        Sem Peso: <strong>{productsFoundNoWeight}</strong>
      </p>
    </div>
  );

  render() {
    const { title, dataSource, loading } = this.props;
    return (
      <Panel title={title} color="default" noPadding>
        <Table
          pagination={false}
          loading={loading}
          rowKey="clientId"
          dataSource={dataSource}
          columns={this.columns}
        />
      </Panel>
    );
  }
}

export default DashboardGrid;
