import api from "../services/api";
import { notification } from "antd";
import history from "../helpers/history";

/**
 * Action types
 */
export const Types = {
  LOADING: "login/LOADING",
  LOGGED: "login/LOGGED"
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  loading: false,
  logged: false
};

/**
 * Action creators
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.LOADING:
      return { ...state, loading: action.payload };
    case Types.LOGGED:
      return { ...state, logged: action.payload };
    default:
      return state;
  }
};

/**
 * Creators
 */
export const Creators = {
  setLoading: loading => ({
    type: Types.LOADING,
    payload: loading
  }),
  // Logged
  setLogged: logged => ({
    type: Types.LOGGED,
    payload: logged || !!localStorage.getItem("@forshProduct:token")
  }),
  // Auth
  auth: (values, setSubmitting) => dispatch => {
    dispatch([Creators.setLoading(true), setSubmitting(true)]);
    api
      .post("/auth", values)
      .then(response => {
        const { token } = response.data;
        localStorage.setItem("@forshProduct:token", token);

        dispatch([
          Creators.setLoading(false),
          Creators.setLogged(true),
          setSubmitting(false),
          history.push("/dashboard")
        ]);
      })
      .catch(() => {
        notification["error"]({
          message: "Login",
          description: "Usuário e/ou senha incorretos."
        });

        dispatch([Creators.setLoading(false), setSubmitting(false)]);
      });
  },
  logout: () => dispatch => {
    dispatch(Creators.setLoading(true));
    
    localStorage.clear();

    dispatch([
      Creators.setLoading(false),
      Creators.setLogged(false),
      history.push("/login")
    ]);
  }
};
