import api from "../services/api";
import { notification } from "antd";
import history from "../helpers/history";
import messages from "../helpers/messages";

/**
 * Action types
 */
export const Types = {
  LOADING: "user/LOADING",
  GET_ALL: "user/GET_ALL",
  CURRENT_PAGE: "user/CURRENT_PAGE",
  TOTAL_RECORDS: "user/TOTAL_RECORDS"
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  loading: false,
  records: [],
  currentPage: 0,
  totalRecords: 0
};

/**
 * Action creators
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.LOADING:
      return { ...state, loading: action.payload };
    case Types.GET_ALL:
      return { ...state, records: action.payload };
    case Types.CURRENT_PAGE:
      return { ...state, currentPage: action.payload };
    case Types.TOTAL_RECORDS:
      return { ...state, totalRecords: action.payload };
    default:
      return state;
  }
};

/**
 * Creators
 */
export const Creators = {
  setLoading: loading => ({
    type: Types.LOADING,
    payload: loading
  }),
  setCurrentPage: currentPage => ({
    type: Types.CURRENT_PAGE,
    payload: currentPage
  }),
  setTotalRecords: totalRecords => ({
    type: Types.TOTAL_RECORDS,
    payload: totalRecords
  }),
  // Get By Id
  get: (id, setValues) => dispatch => {
    dispatch(Creators.setLoading(true));
    api
      .get(`/users/${id}`)
      .then(response => {
        const { content } = response.data;
        dispatch([setValues(content), Creators.setLoading(false)]);
      })
      .catch(() => {
        dispatch(Creators.setLoading(false));
      });
  },
  // Get All
  getAll: (index, size) => dispatch => {
    dispatch(Creators.setLoading(true));
    api
      .get(`/users?pageIndex=${index}&pageSize=${size}`)
      .then(response => {
        const { content, totalRegister } = response.data;
        dispatch([
          Creators.setTotalRecords(totalRegister),
          Creators.setCurrentPage(index),
          Creators.setLoading(false),
          { type: Types.GET_ALL, payload: content }
        ]);
      })
      .catch(() => {
        dispatch(Creators.setLoading(false));
      });
  },
  // Add
  add: (values, resetForm, setErrors, setSubmitting) => dispatch => {
    dispatch([Creators.setLoading(true), setSubmitting(true)]);
    api
      .post("/users", values)
      .then(() => {
        notification["success"]({
          message: "Usuários",
          description: messages.SUCCESS
        });

        dispatch([
          resetForm(),
          setSubmitting(false),
          Creators.setLoading(false),
          history.push("/client")
        ]);
      })
      .catch(ex => {
        ex && setErrors(ex.errors);

        notification["error"]({
          message: "Usuários",
          description:
            messages.EXCEPTION
        });

        dispatch([Creators.setLoading(false), setSubmitting(false)]);
      });
  },
  // Update
  update: (values, setErrors, setSubmitting) => dispatch => {
    dispatch([Creators.setLoading(true), setSubmitting(true)]);
    api
      .put("/users", values)
      .then(() => {
        notification["success"]({
          message: "Usuários",
          description: messages.SUCCESS
        });

        dispatch([
          setSubmitting(false),
          Creators.setLoading(false),
          history.push("/client")
        ]);
      })
      .catch(ex => {
        ex && setErrors(ex.errors);

        notification["error"]({
          message: "Usuários",
          description: messages.EXCEPTION
        });

        dispatch([Creators.setLoading(false), setSubmitting(false)]);
      });
  }
};
