import { create } from "apisauce";

const api = create({
  baseURL:
    process.env.NODE_ENV === "production"
            ? "https://bdp.omnixcommerce.com.br/api"
            : "http://localhost:5000/api"
});

api.addRequestTransform(request => {
  request.headers["Authorization"] = `Bearer ${localStorage.getItem(
    "@forshProduct:token"
  )}`;
});

api.addResponseTransform(response => {
  if (!response.ok) throw response.data;
  
});
export default api;
