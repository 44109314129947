import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as ProductActions } from "../../../ducks/product";


function ImagesTable({ images, productId ,  productActions }) {

  function handleDelete(id){
    productActions.deleteImagem(id, productId)
  }

  return (
    <table>
      <thead className="ant-table-thead">
        <tr>
          <th width="10%">
            <span class="ant-table-header-column">
              <div>
                <span class="ant-table-column-title">Ordem</span>
                <span class="ant-table-column-sorter"></span>
              </div>
            </span>
          </th>
          <th width="15%">
            <span class="ant-table-header-column">
              <div>
                <span class="ant-table-column-title">Imagem</span>
                <span class="ant-table-column-sorter"></span>
              </div>
            </span>
          </th>
          <th>
            <span class="ant-table-header-column">
              <div>
                <span class="ant-table-column-title">Url</span>
                <span class="ant-table-column-sorter"></span>
              </div>
            </span>
          </th>
          <th width="10%" className="ant-table-row-cell-last">
            <span class="ant-table-header-column">
              <div>
                <span class="ant-table-column-title">Ação</span>
                <span class="ant-table-column-sorter"></span>
              </div>
            </span>
          </th>
        </tr>
      </thead>
      <tbody className="ant-table-tbody">
        {images.map((image, index) => (
          <tr
            className="ant-table-row ant-table-row-level-0"
            data-row-key={index}
          >
            <td>{index}</td>
            <td>
              <img
                style={{ width: "100px" }}
                className="img-fluid"
                src={image.url}
              />
            </td>
            <td>
              <input className="ant-input" name="imageUrl" value={image.url} />
            </td>
            <td>
              <button
                type="button"
                className={`ant-btn w-100 ant-btn-danger`}
                onClick={() => handleDelete(image.id)}
              >
                Excluir
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const mapDispatchToProps = (dispatch) => ({
  productActions: bindActionCreators(ProductActions, dispatch),
});

export default connect(null, mapDispatchToProps)(ImagesTable);