import React, { lazy, Suspense } from "react";

import Header from "../../components/theme/header";
import Content from "../../components/theme/content";
import { Row, Column } from "../../components/bootstrap";

const Grid = lazy(() => import("./notFoundGrid"));
const Search = lazy(() => import("./notFoundSearch"));

const NotFound = () => {
  return (
    <Suspense
      fallback={
        <div>
          <br />
          Carregando...
        </div>
      }
    >
      <Header title="Produtos Não Encontrados" />
      <Content>
        <Row>
          <Column col="12">
            <Search />
          </Column>
        </Row>
        <Row>
          <Column col="12">
            <Grid title="Produtos Não Encontrados" />
          </Column>
        </Row>
      </Content>
    </Suspense>
  );
};

export default NotFound;
