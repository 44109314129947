import api from "../services/api";
import history from "../helpers/history";
import { notification } from "antd";
import messages from "../helpers/messages";

/**
 * Action types
 */
export const Types = {
  LOADING: "product/LOADING",
  GET: "product/GET",
  GET_ALL: "product/GET_ALL",
  GET_IMAGES: "product/GET_IMAGES",
  CURRENT_PAGE: "product/CURRENT_PAGE",
  TOTAL_RECORDS: "product/TOTAL_RECORDS",
  URL_SOURCE: "product/URL_SOURCE",
  SET_SEARCH: "product/SET_SEARCH",
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  loading: false,
  record: null,
  records: [],
  images: [],
  currentPage: 0,
  totalRecords: 0,
  urlSource: "",
  search: {
    client: "",
    name: "",
    ean: "",
  },
};

/**
 * Action creators
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.LOADING:
      return { ...state, loading: action.payload };
    case Types.GET:
      return { ...state, record: action.payload };
    case Types.GET_ALL:
      return { ...state, records: action.payload };
    case Types.GET_IMAGES:
      return { ...state, images: action.payload };
    case Types.CURRENT_PAGE:
      return { ...state, currentPage: action.payload };
    case Types.TOTAL_RECORDS:
      return { ...state, totalRecords: action.payload };
    case Types.URL_SOURCE:
      return { ...state, urlSource: action.payload };
    case Types.SET_SEARCH:
      return { ...state, search: action.payload };
    default:
      return state;
  }
};

/**
 * Creators
 */
export const Creators = {
  setLoading: (loading) => ({
    type: Types.LOADING,
    payload: loading,
  }),
  setCurrentPage: (currentPage) => ({
    type: Types.CURRENT_PAGE,
    payload: currentPage,
  }),
  setTotalRecords: (totalRecords) => ({
    type: Types.TOTAL_RECORDS,
    payload: totalRecords,
  }),
  setRecord: (record) => ({
    type: Types.GET,
    payload: record,
  }),
  setUrlSource: (url) => ({
    type: Types.URL_SOURCE,
    payload: url,
  }),
  setSearch: (search) => ({
    type: Types.SET_SEARCH,
    payload: search,
  }),
  // Get By Id
  getImages: (productId) => (dispatch) => {
    dispatch(Creators.setLoading(true));
    api
      .get(`/products/images/${productId}`)
      .then((response) => {
        debugger;
        const { content } = response.data;
        dispatch([
          { type: Types.GET_IMAGES, payload: content },
          Creators.setLoading(false),
        ]);
      })
      .catch(() => {
        dispatch(Creators.setLoading(false));
      });
  },
  // Get By Id
  get: (id, setValues) => (dispatch) => {
    dispatch(Creators.setLoading(true));
    api
      .get(`/products/${id}`)
      .then((response) => {
        const { content } = response.data;
        dispatch([setValues(content), Creators.setLoading(false)]);
      })
      .catch(() => {
        dispatch(Creators.setLoading(false));
      });
  },
  // Get All
  getAll: (index, size) => (dispatch, getState) => {
    dispatch(Creators.setLoading(true));
    const { client, name, ean } = getState().ProductReducer.search;
    api
      .get(
        `/products?clientId=${client}&name=${name}&ean=${ean}&index=${index}&size=${size}`
      )
      .then((response) => {
        const { content, records } = response.data;
        dispatch([
          Creators.setTotalRecords(records),
          Creators.setCurrentPage(index),
          Creators.setLoading(false),
          { type: Types.GET_ALL, payload: content },
        ]);
      })
      .catch(() => {
        dispatch(Creators.setLoading(false));
      });
  },
  // Update
  update: (values, setErrors, setSubmitting) => (dispatch, getState) => {
    dispatch([Creators.setLoading(true), setSubmitting(true)]);

    const { urlSource } = getState().ProductReducer;
    api
      .put("/products", values)
      .then(() => {
        notification["success"]({
          message: "Produtos",
          description: messages.SUCCESS,
        });

        dispatch([
          setSubmitting(false),
          Creators.setLoading(false),
          Creators.getAll(null, 0, 10),
          history.push(`/product${urlSource}`),
        ]);
      })
      .catch((ex) => {
        ex && setErrors(ex.errors);

        notification["error"]({
          message: "Produtos",
          description: messages.EXCEPTION,
        });

        dispatch([Creators.setLoading(false), setSubmitting(false)]);
      });
  },
  // Deletar Imagem
  deleteImagem: (imagemId, productId) => (dispatch) => {
    dispatch(Creators.setLoading(true));
    api
      .delete(`products/removeImage/${imagemId}`)
      .then(() => {
        dispatch(Creators.getImages(productId));
        notification.open({
          message: "Imagem excluída",
          description:
            "A imagem foi excluída com sucesso.",
        });
      })
      .catch(() => {
        dispatch(Creators.setLoading(false));
      });
  },
};
