import React, { Component } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import { Button } from "antd";

import { Row, Form } from "../../components/bootstrap";
import { InputText, InputPassword } from "../../components/form";
import Messages from "../../helpers/messages";

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as LoginActions } from "../../ducks/login";

class InnerForm extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(name, value) {
    this.props.setFieldValue(name, value);
  }

  render() {
    const {
      values,
      errors,
      isSubmitting,
      handleSubmit,
      handleChange
    } = this.props;

    return (
      <Form handleSubmit={handleSubmit} className="m-t">
        <Row>
          <InputText
            name="email"
            handleChange={handleChange}
            col={12}
            required
            error={errors.email}
            value={values.email}
            label="E-mail"
          />
        </Row>
        <Row>
          <InputPassword
            name="password"
            handleChange={handleChange}
            col={12}
            required
            error={errors.password}
            value={values.password}
            label="Senha"
          />
        </Row>
        <Button
          htmlType="submit"
          className="col-md-12"
          type="primary"
          loading={isSubmitting}
        >
          Entrar
        </Button>
      </Form>
    );
  }
}

const LoginForm = withFormik({
  validateOnChange: false,
  mapPropsToValues: ({ email, password }) => ({
    email: email || "",
    password: password || ""
  }),
  validationSchema: Yup.object().shape({
    email: Yup.string().required(Messages.REQUIRED),
    password: Yup.string().required(Messages.REQUIRED)
  }),
  handleSubmit(values, { props, setSubmitting }) {
    props.loginActions.auth(values, setSubmitting);
  }
})(InnerForm);

const mapDispatchToProps = dispatch => ({
  loginActions: bindActionCreators(LoginActions, dispatch)
});

export default connect(
  null,
  mapDispatchToProps
)(LoginForm);
