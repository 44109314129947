import React, { lazy, Suspense } from "react";

import Header from "../../../components/theme/header";
import Content from "../../../components/theme/content";

const Form = lazy(() => import("./form"));

const Index = () => {
  return (
    <Suspense
      fallback={
        <div>
          <br />
          Carregando...
        </div>
      }
    >
      <Header title="Importar Planilha de Produtos" />
      <Content>
        <Form />
      </Content>
    </Suspense>
  );
};

export default Index;
