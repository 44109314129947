import api from "../services/api";

/**
 * Action types
 */
export const Types = {
  LOADING: "productInconsistency/LOADING",
  GET_ALL: "productInconsistency/GET_ALL",
  CURRENT_PAGE: "productInconsistency/CURRENT_PAGE",
  TOTAL_RECORDS: "productInconsistency/TOTAL_RECORDS",
  SET_SEARCH: "productInconsistency/SET_SEARCH"
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  loading: false,
  records: [],
  currentPage: 0,
  totalRecords: 0,
  search: {
    client: "",
    name: "",
    ean: ""
  }
};

/**
 * Action creators
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.LOADING:
      return { ...state, loading: action.payload };
    case Types.GET_ALL:
      return { ...state, records: action.payload };
    case Types.CURRENT_PAGE:
      return { ...state, currentPage: action.payload };
    case Types.TOTAL_RECORDS:
      return { ...state, totalRecords: action.payload };
    case Types.SET_SEARCH:
      return { ...state, search: action.payload };
    default:
      return state;
  }
};

/**
 * Creators
 */
export const Creators = {
  setLoading: loading => ({
    type: Types.LOADING,
    payload: loading
  }),
  setCurrentPage: currentPage => ({
    type: Types.CURRENT_PAGE,
    payload: currentPage
  }),
  setTotalRecords: totalRecords => ({
    type: Types.TOTAL_RECORDS,
    payload: totalRecords
  }),
  setSearch: search => ({
    type: Types.SET_SEARCH,
    payload: search
  }),
  // Get All
  getAll: (index, size) => (dispatch, getState) => {
    dispatch(Creators.setLoading(true));
    const { client, name, ean } = getState().ProductInconsistencyReducer.search;
    api
      .get(
        `/productsInconsistency?clientId=${client}&name=${name}&ean=${ean}&index=${index}&size=${size}`
      )
      .then(response => {
        const { content, records } = response.data;
        dispatch([
          Creators.setTotalRecords(records),
          Creators.setCurrentPage(index),
          Creators.setLoading(false),
          { type: Types.GET_ALL, payload: content }
        ]);
      })
      .catch(() => {
        dispatch(Creators.setLoading(false));
      });
  }
};
