import api from "../services/api";

/**
 * Action types
 */
export const Types = {
  LOADING: "dashbord/LOADING",
  GET: "dashbord/GET"
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  loading: false,
  totalClients: 0,
  totalProducts: 0,
  totalProductsNotFound: 0,
  totalsPerClient: []
};

/**
 * Action creators
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.LOADING:
      return { ...state, loading: action.payload };
    case Types.GET:
      return {
        ...state,
        totalClients: action.totalClients,
        totalProducts: action.totalProducts,
        totalProductsNotFound: action.totalProductsNotFound,
        totalsPerClient: action.totalsPerClient
      };
    default:
      return state;
  }
};

/**
 * Creators
 */
export const Creators = {
  setLoading: loading => ({
    type: Types.LOADING,
    payload: loading
  }),
  // Get dashbord
  get: () => dispatch => {
    dispatch(Creators.setLoading(true));
    api
      .get(`/dashboard`)
      .then(response => {
        const {
          totalClients,
          totalProducts,
          totalProductsNotFound,
          totalsPerClient
        } = response.data.content;

        dispatch([
          {
            type: Types.GET,
            totalClients,
            totalProducts,
            totalProductsNotFound,
            totalsPerClient
          },
          Creators.setLoading(false)
        ]);
      })
      .catch(() => {
        dispatch(Creators.setLoading(false));
      });
  }
};
