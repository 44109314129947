import api from "../services/api";

/**
 * Action types
 */
export const Types = {
  LOADING: "productError/LOADING",
  GET_ALL: "productError/GET_ALL",
  CURRENT_PAGE: "productError/CURRENT_PAGE",
  TOTAL_RECORDS: "productError/TOTAL_RECORDS"
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  loading: false,
  records: [],
  currentPage: 0,
  totalRecords: 0
};

/**
 * Action creators
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.LOADING:
      return { ...state, loading: action.payload };
    case Types.GET_ALL:
      return { ...state, records: action.payload };
    case Types.CURRENT_PAGE:
      return { ...state, currentPage: action.payload };
    case Types.TOTAL_RECORDS:
      return { ...state, totalRecords: action.payload };
    default:
      return state;
  }
};

/**
 * Creators
 */
export const Creators = {
  setLoading: loading => ({
    type: Types.LOADING,
    payload: loading
  }),
  setCurrentPage: currentPage => ({
    type: Types.CURRENT_PAGE,
    payload: currentPage
  }),
  setTotalRecords: totalRecords => ({
    type: Types.TOTAL_RECORDS,
    payload: totalRecords
  }),
  remove: ean => dispatch => {
    dispatch(Creators.setLoading(true));
    api
      .delete(`/eanCrawlerErrors/${ean}`)
      .then(() => {
        dispatch(Creators.getAll(0, 10));
      })
      .catch(() => {
        dispatch(Creators.setLoading(false));
      });
  },
  // Get All
  getAll: (index, size) => dispatch => {
    dispatch(Creators.setLoading(true));
    api
      .get(`/eanCrawlerErrors/products?index=${index}&size=${size}`)
      .then(response => {
        const { content, records } = response.data;
        dispatch([
          Creators.setTotalRecords(records),
          Creators.setCurrentPage(index),
          Creators.setLoading(false),
          { type: Types.GET_ALL, payload: content }
        ]);
      })
      .catch(() => {
        dispatch(Creators.setLoading(false));
      });
  }
};
