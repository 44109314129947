import React, { lazy, Suspense } from "react";

import Header from "../../components/theme/header";
import Content from "../../components/theme/content";

const Grid = lazy(() => import("./errorGrid"));

const Index = () => {
  return (
    <Suspense
      fallback={
        <div>
          <br />
          Carregando...
        </div>
      }
    >
      <Header title="Produtos com Erros na Busca" />
      <Content>
        <Grid title="Produtos com Erros na Busca (Robô)" />
      </Content>
    </Suspense>
  );
};

export default Index;
